import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ isDarkMode }) => {
  return (
    <footer className={isDarkMode ? 'footer dark-mode' : 'footer light-mode'}>
      <div className="footer-content">
        <img src="/imagenes/logo.png" alt="Logo" className="footer-logo"/>
        <div className="footer-text">
          <ul className="footer-menu">
            <li className="footer-menu-item"><Link to="/sobrenosotros">Sobre Nosotros</Link></li>
            <li className="footer-menu-item"><Link to="/contacto">Contacto</Link></li>
          </ul>
          <p>Instituto Punto Alejandría © 2024</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
