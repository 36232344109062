import React from 'react';
import '../App.css';

const Productos = () => {
  return (
    <div className="content">
      <h1>Productos Disponibles</h1>
      <div className="introduccion">
        <p>En esta sección, encontrarás una variedad de estándares de competencia que ofrecemos.</p>
      </div>
      <div className="producto">
        <h2>Estándar de Competencia EC0301: Diseño de cursos de formación del capital humano de manera presencial grupal, sus instrumentos de evaluación y manuales del curso</h2>
        <p>Es referente a todas las actividades que la persona lleva a cabo durante el diseño de cursos de
          formación del capital humano, diseñan cursos de capacitación presenciales, elaborando
          la carta descriptiva y seleccionando las técnicas de instrucción, diseñan instrumentos para la
          evaluación de cursos de capacitación presenciales, elaborando los reactivos del instrumento de
          evaluación y diseñando el instrumento para la evaluación de satisfacción del curso, y desarrollan
          manuales del curso de capacitación presenciales, elaborando el manual del instructor y
          seleccionando las fuentes de información del manual del participante. 
        </p>
        <a href="https://conocer.gob.mx/RENEC/fichaEstandar.do?method=obtenerPDFEstandar&idEstandar=1544" target="_blank" rel="noopener noreferrer" className="enlace-producto">Ver más</a>
      </div>
      <div className="producto">
        <h2>Estándar de Competencia EC217.01: Impartición de cursos de formación del capital humano de manera presencial grupal</h2>
        <p>Contempla las funciones sustantivas de preparar, conducir y evaluar cursos de
          capacitación. Preparar la sesión mediante la planeación y la comprobación de la existencia
          y el funcionamiento de los recursos requeridos para la sesión. Conducir la sesión realizando el encuadre,
          desarrollo y cierre, empleando técnicas instruccionales y grupales que faciliten el proceso de
          aprendizaje. Evaluar el aprendizaje antes, durante y al final del curso, considerando la satisfacción de
          los participantes/capacitandos.
        </p>
        <a href="https://www.conocer.gob.mx/contenido/publicaciones_dof/2021/EC0217.01.pdf" target="_blank" rel="noopener noreferrer" className="enlace-producto">Ver más</a>
      </div>
      <div className="producto">
        <h2>Estándar de Competencia EC0076: Evaluación de la competencia de candidatos con base en Estándares de Competencia</h2>
        <p>Contempla las funciones elementales que una persona debe desempeñar para la
          evaluación de quienes evalúan la competencia laboral de candidatos, con base en Estándares de
          Competencia.
        </p>
        <a href="https://www.conocer.gob.mx/contenido/publicaciones_dof/2022/EC0076.pdf" target="_blank" rel="noopener noreferrer" className="enlace-producto">Ver más</a>
      </div>
      <div className="producto">
        <h2>Estándar de Competencia EC0366: Desarrollo de cursos de formación en línea</h2>
        <p>Contempla las competencias que un desarrollador de cursos para
          formación en línea emplea en la planificación del desarrollo de un curso de formación en línea, la
          elaboración de contenidos y verificación del funcionamiento de éste.
        </p>
        <a href="https://conocer.gob.mx/RENEC/fichaEstandar.do?method=obtenerPDFEstandar&idEstandar=1627" target="_blank" rel="noopener noreferrer" className="enlace-producto">Ver más</a>
      </div>
    </div>
  );
}

export default Productos;
