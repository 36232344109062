import React from 'react';
import '../App.css';

const pagUser = () => {
    return (
        <div className="content">
            <h1>Usuario</h1>
            <p>¡Bienvenido Usuario!</p>
        </div>
    );
};

export default pagUser;
