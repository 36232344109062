import React from 'react';
import '../App.css';

const Servicios = () => {
  return (
    <div className="content">
      <h1>Servicios</h1>
      <p>Próximamente...</p>
    </div>
  );
}

export default Servicios;